import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import PublishIcon from '@mui/icons-material/Publish'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import {Box, Button, Typography} from '@mui/material'
import {isNull} from 'lodash'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode, WebsiteQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {IconNavigationRail} from '../../../../common/IconNavigationRail'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {
  PeristentDrawerHeader,
  PersistentDrawerWithHeaderAndFooter
} from '../../../../common/PersistentDrawerWithContent'
import {SecondaryHeader} from '../../Header'
import {
  useDiscardWebsitePagesChanges,
  usePublishWebsitePagesChanges,
  useUploadClientFile,
  useWebsite
} from '../graphql'
import {WebsiteEditorContent} from '../types'
import {UpdateWebsiteDrawer} from '../UpdateWebsiteDrawer'
import {useTranslateWebsiteEditorContent} from '../utils'
import {CreateWebsitePageDrawer} from './CreateWebsitePageDrawer'
import {DrawerContent} from './DrawerContent'
import {PageContent} from './PageContent'
import {UpdateEcommerceSettingsDrawer} from './updateEcommerceSettingsDrawer'
import {UpdateWebsiteMetadataDrawer} from './updateWebsiteMetadataDrawer'
import {UpdateWebsiteScriptDrawer} from './updateWebsiteScriptsDrawer'
import {useWebsitePageContext} from './WebsitePageContext'
import {WebsiteSettingsButton} from './WebsiteSettingsButton'

const useNavigationItems = ({
  openChangesMenu,
  isChangesMenuDisabled
}: {
  openChangesMenu: (e: SyntheticEvent) => void
  isChangesMenuDisabled?: boolean
}) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {selectedWebsiteEditorContent, setSelectedWebsiteEditorContent} =
    useWebsitePageContext()
  return useMemo(
    () =>
      [
        {
          id: 'pages',
          Icon: DescriptionIcon,
          onClick: () =>
            setSelectedWebsiteEditorContent(WebsiteEditorContent.Pages),
          selected: selectedWebsiteEditorContent === WebsiteEditorContent.Pages,
          tooltip: t('Pages')
        },
        {
          id: 'blocks',
          Icon: ViewInArIcon,
          onClick: () =>
            setSelectedWebsiteEditorContent(WebsiteEditorContent.Blocks),
          selected:
            selectedWebsiteEditorContent === WebsiteEditorContent.Blocks,
          tooltip: t('Blocks')
        },
        {
          id: 'assets',
          Icon: AttachFileIcon,
          onClick: () =>
            setSelectedWebsiteEditorContent(WebsiteEditorContent.Assets),
          selected:
            selectedWebsiteEditorContent === WebsiteEditorContent.Assets,
          divider: true,
          tooltip: t('Assets')
        },
        ...(P([PermissionCode.PublishWebsitePagesChanges]) ||
        P([PermissionCode.DiscardWebsitePagesChanges])
          ? [
              {
                id: 'changes',
                Icon: PublishIcon,
                onClick: openChangesMenu,
                disabled: isChangesMenuDisabled,
                tooltip: isChangesMenuDisabled
                  ? t('There are no changes to publish')
                  : undefined,
                badge: !isChangesMenuDisabled
              }
            ]
          : [])
      ].filter(Boolean),
    [
      P,
      isChangesMenuDisabled,
      openChangesMenu,
      selectedWebsiteEditorContent,
      setSelectedWebsiteEditorContent,
      t
    ]
  )
}

export const WebsiteDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    websitePagesLoading,
    websitePagesError,
    websiteId,
    selectedWebsiteEditorContent,
    setSelectedWebsiteEditorContent,
    websitePages,
    websiteHeadersError,
    websiteHeadersLoading,
    websiteFootersError,
    websiteFootersLoading,
    refetchWebsitePages
  } = useWebsitePageContext()
  const {data, loading, error} = useWebsite(websiteId, isNaN(websiteId))
  const [title, setTitle] = useState<string>(
    t('Website #{{id}}', {id: websiteId})
  )
  const {
    state: isCreateWebsitePageDrawerOpen,
    setTrue: openCreateWebsitePageDrawer,
    setFalse: closeCreateWebsitePageDrawer
  } = useBooleanState(false)
  const {
    state: isPublishChangesDialogOpen,
    setTrue: openPublishChangesDialog,
    setFalse: closePublishChangesDialog
  } = useBooleanState(false)
  const {
    state: isDischardChangesDialogOpen,
    setTrue: openDischardChangesDialog,
    setFalse: closeDischardChangesDialog
  } = useBooleanState(false)
  const {
    anchorEl: changesAnchorEl,
    openMenu: openChangesMenu,
    closeMenu: closeChangesMenu
  } = useMenu()
  const navigationItems = useNavigationItems({
    openChangesMenu,
    isChangesMenuDisabled: isNull(data?.website.pages)
  })
  const translateWebsiteEditorContent = useTranslateWebsiteEditorContent()
  const uploadClientFile = useUploadClientFile()
  const publishWebsitePagesChanges = usePublishWebsitePagesChanges()
  const discardWebsitePagesChanges = useDiscardWebsitePagesChanges()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {formatDateNumeric, formatTimeWithSeconds} = useDateTimeFormatters()
  const history = useHistory()
  const handleBackClick = useCallback(
    () => history.push(routeTo.admin.websites.index()),
    [history]
  )
  const handleFileUpload = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0]
        try {
          setShowBackdrop(true)
          await uploadClientFile({file, filePath: file.name})
          addInfoNotification(t('File has been uploaded'))
        } catch (error) {
          defaultErrorHandler(error, t('Error while uploading client file'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      uploadClientFile
    ]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.websites.detail(websiteId)),
    [history, websiteId]
  )
  const {getInputProps, open: openFilePicker} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileUpload,
    multiple: false,
    maxSize: 10485760,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/gif': [],
      'image/svg+xml': [],
      'image/webp': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [],
      'text/plain': [],
      'text/csv': [],
      'application/vnd.oasis.opendocument.text': [],
      'application/vnd.oasis.opendocument.spreadsheet': [],
      'application/vnd.oasis.opendocument.presentation': [],
      'application/zip': [],
      'application/x-zip-compressed': [],
      'multipart/x-zip': [],
      'application/vnd.rar': [],
      'application/x-rar-compressed': []
    }
  })
  const handlePublishChanges = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await publishWebsitePagesChanges({websiteId})
      addInfoNotification(t('Website changes has been published'))
      refetchWebsitePages()
      closePublishChangesDialog()
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while publishing website page changes')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    closePublishChangesDialog,
    defaultErrorHandler,
    publishWebsitePagesChanges,
    setShowBackdrop,
    t,
    websiteId,
    refetchWebsitePages
  ])
  const handleDiscardChanges = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await discardWebsitePagesChanges({websiteId})
      addInfoNotification(t('Website changes has been discarded'))
      refetchWebsitePages()
      closeDischardChangesDialog()
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while discarding website page changes')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    closeDischardChangesDialog,
    defaultErrorHandler,
    discardWebsitePagesChanges,
    setShowBackdrop,
    t,
    websiteId,
    refetchWebsitePages
  ])
  useEffect(() => {
    if (data) {
      setTitle(data.website.name)
    }
  }, [data])
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleBackClick}
        />
      }
    >
      <RenderOnData<WebsiteQuery>
        data={data}
        loading={
          loading ||
          websitePagesLoading ||
          websiteHeadersLoading ||
          websiteFootersLoading
        }
        error={
          error ||
          websitePagesError ||
          websiteHeadersError ||
          websiteFootersError
        }
        errorMessage={
          error
            ? t<string>('Error while loading website')
            : websitePagesError
            ? t<string>('Error while loading website pages')
            : websiteHeadersError
            ? t<string>('Error while loading website headers')
            : t<string>('Error while loading website footers')
        }
        dataCondition={(data) => Boolean(data.website)}
      >
        {({website}) => (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                width: '100%',
                height: '100%'
              }}
            >
              <IconNavigationRail
                items={navigationItems}
                bottomIcon={<WebsiteSettingsButton websiteId={website.id} />}
              />
              <PersistentDrawerWithHeaderAndFooter
                drawerWidth={360}
                content={<PageContent website={website} />}
                drawerContent={
                  <DrawerContent
                    onCreatePageButtonClick={
                      P([PermissionCode.CreateWebsitePage])
                        ? openCreateWebsitePageDrawer
                        : undefined
                    }
                  />
                }
                isOpen={!!selectedWebsiteEditorContent}
                header={
                  <PeristentDrawerHeader
                    title={
                      selectedWebsiteEditorContent
                        ? translateWebsiteEditorContent(
                            selectedWebsiteEditorContent
                          )
                        : ''
                    }
                    onClose={() => setSelectedWebsiteEditorContent(undefined)}
                  />
                }
                footer={
                  selectedWebsiteEditorContent === WebsiteEditorContent.Pages &&
                  websitePages.length > 0 &&
                  P([PermissionCode.CreateWebsitePage]) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openCreateWebsitePageDrawer}
                    >
                      {t('Create')}
                    </Button>
                  ) : (
                    selectedWebsiteEditorContent ===
                      WebsiteEditorContent.Assets &&
                    P([PermissionCode.UploadClientFile]) && (
                      <Button
                        startIcon={<FileUploadIcon />}
                        variant="contained"
                        color="primary"
                        onClick={openFilePicker}
                      >
                        {t('Upload')}
                        <input {...getInputProps()} />
                      </Button>
                    )
                  )
                }
              />
            </Box>
            <CreateWebsitePageDrawer
              isOpen={isCreateWebsitePageDrawerOpen}
              onClose={closeCreateWebsitePageDrawer}
              website={website}
            />
            <Menu
              anchorEl={changesAnchorEl}
              onClose={closeChangesMenu}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
            >
              <Typography
                sx={{px: 3, py: 1.5}}
                variant="overline"
                color="textSecondary"
              >
                {[
                  t('Updated'),
                  website.pagesUpdatedAt &&
                    [
                      formatDateNumeric(new Date(website.pagesUpdatedAt)),
                      formatTimeWithSeconds(new Date(website.pagesUpdatedAt))
                    ].join(', ')
                ]
                  .filter(Boolean)
                  .join(' ')}
              </Typography>
              {P([PermissionCode.PublishWebsitePagesChanges]) && (
                <MenuItem
                  label={t('Publish changes')}
                  onClick={() => {
                    openPublishChangesDialog()
                    closeChangesMenu()
                  }}
                  icon={<PublishIcon />}
                />
              )}
              {P([PermissionCode.DiscardWebsitePagesChanges]) && (
                <MenuItem
                  label={t('Discard changes')}
                  onClick={() => {
                    openDischardChangesDialog()
                    closeChangesMenu()
                  }}
                  icon={<DeleteIcon />}
                />
              )}
            </Menu>
            <ConfirmationDialog
              title={t('Publish changes?')}
              contentText={t(
                'Are you sure you want to publish the changes? Once published, the updated content will be visible to all users. Make sure to review everything before proceeding.'
              )}
              confirmButtonLabel={t('Publish')}
              isOpen={isPublishChangesDialogOpen}
              onCancel={closePublishChangesDialog}
              onConfirm={handlePublishChanges}
            />
            <ConfirmationDialog
              title={t('Discard changes?')}
              contentText={t(
                'Are you sure you want to discard the changes? All unsaved edits will be lost, and the content will revert to its previous version. This action cannot be undone.'
              )}
              confirmButtonLabel={t('Discard')}
              isOpen={isDischardChangesDialogOpen}
              onCancel={closeDischardChangesDialog}
              onConfirm={handleDiscardChanges}
            />
          </>
        )}
      </RenderOnData>
      {P([PermissionCode.UpdateWebsite, PermissionCode.ReadWebsite]) && (
        <Route path={routeTo.admin.websites.edit(':websiteId')} exact>
          <UpdateWebsiteDrawer onExited={handleExited} />
        </Route>
      )}
      {P([
        PermissionCode.UpdateWebsiteCommonSettings,
        PermissionCode.ReadWebsiteCommonSettings,
        PermissionCode.UpdateWebsiteEcommerceSettings
      ]) && (
        <Route
          path={routeTo.admin.websites.editEcommerceSettings(':websiteId')}
          exact
        >
          <UpdateEcommerceSettingsDrawer onExited={handleExited} />
        </Route>
      )}
      {P([
        PermissionCode.UpdateWebsiteCommonSettings,
        PermissionCode.ReadWebsiteCommonSettings,
        PermissionCode.UpdateWebsiteMetadata
      ]) && (
        <Route
          path={routeTo.admin.websites.editWebsiteMetadata(':websiteId')}
          exact
        >
          <UpdateWebsiteMetadataDrawer onExited={handleExited} />
        </Route>
      )}
      {P([
        PermissionCode.UpdateWebsiteCommonSettings,
        PermissionCode.ReadWebsiteCommonSettings,
        PermissionCode.UpdateWebsiteScripts
      ]) && (
        <Route
          path={routeTo.admin.websites.editWebsiteScripts(':websiteId')}
          exact
        >
          <UpdateWebsiteScriptDrawer onExited={handleExited} />
        </Route>
      )}
    </PageWithHeaderTemplate>
  )
}
