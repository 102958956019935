import {Badge, Box, Divider, IconButton, SvgIconProps} from '@mui/material'
import React, {SyntheticEvent} from 'react'
import {PRIMARY_100_COLOR} from '../../theme'
import {Tooltip} from './Tooltip'

interface INavigationRailItemProps {
  selected?: boolean
  onClick: (e: SyntheticEvent) => void
  Icon: React.FC<SvgIconProps>
  tooltip?: string
  divider?: boolean
  disabled?: boolean
  badge?: boolean
}

const NavigationRailItem: React.FC<INavigationRailItemProps> = ({
  selected,
  Icon,
  onClick,
  tooltip,
  divider,
  disabled,
  badge
}: INavigationRailItemProps) => (
  <Tooltip title={tooltip}>
    <div>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        color={selected ? 'primary' : undefined}
        sx={{
          backgroundColor: selected ? PRIMARY_100_COLOR : 'transparent',
          '&:hover': {
            backgroundColor: selected ? PRIMARY_100_COLOR : 'grey.300'
          }
        }}
      >
        <Badge color="primary" variant={badge ? 'dot' : undefined}>
          <Icon />
        </Badge>
      </IconButton>
      {divider && <Divider />}
    </div>
  </Tooltip>
)

type Item = INavigationRailItemProps & {id: string}

interface IIconNavigationRailProps {
  items: Item[]
  bottomIcon?: React.ReactNode
}

export const IconNavigationRail: React.FC<IIconNavigationRailProps> = ({
  items,
  bottomIcon
}: IIconNavigationRailProps) => (
  <Box
    sx={{
      bottom: 0,
      height: 'auto',
      width: 72,
      alignItems: 'center',
      borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
      backgroundColor: 'background.paper'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 1,
        gap: 1,
        height: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        {items.map((item) => (
          <NavigationRailItem key={item.id} {...item} />
        ))}
      </Box>
      {bottomIcon}
    </Box>
  </Box>
)
